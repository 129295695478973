import './App.css';
import Home from "./views/home/home.jsx";
import React from 'react';

function App() {
  return ( 
  <Home />
  );
}

export default App;