import './style.scss';
import React from 'react';
import ParticlesJS from "particlesjs";

class Home extends React.Component {

    componentDidMount() {
        var particles = ParticlesJS.init({
            selector: '.background',
              color: ['#00005A', '#ffffff'],
            speed: 0.2,
                    maxParticles: 200,
            connectParticles: true,
            responsive: [{
                breakpoint: 800,
                options: {
                    color: ['#00005A', '#ffffff'],
                    maxParticles: 60,
                }
            }]
        });
    }

    render() {
        return ( 
            <div className="threshold" style={{height: window.innerHeight}} >
                <div id="logo-wrapper">
                    <img id="logo" src="assets/images/logo.png" alt="Logo"/>
                </div>

                <div id="header-buttons">
                    <div className="header-button active">HOME</div>
                    <div className="header-button">STRATEGIES</div>
                    <div className="header-button">INVESTORS</div>
                </div>

                <div id="threshold-verbiage">
                    <span className="emphasis">INVESTOR</span> FOCUSED.<br/>
                    <span className="emphasis">INTELLIGENCE</span> DRIVEN.
                </div>

                    <canvas className="background"></canvas>
                <img src="assets/images/apartments.png" id="threshold-overlay" alt="Apartments"/>
            </div>
        );
    }
}

export default Home;